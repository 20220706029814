{
  "version": "1.12.0",
  "browserslist": "Chrome 77",
  "scripts": {
    "inject-env": "react-inject-env set -v __CONFIG__ -n 000.config.chunk.js -d dist",
    "clean": "find ./dist -type f ! -name '.gitkeep' -delete && rm -rf .parcel-cache .cache",
    "qa:all": "bash ./qa-commit.sh",
    "build": "parcel build index.html hoi-livepreview.html tickers.js snkrs.js --detailed-report --public-url ./ && yarn build:pixelmap-schema",
    "build:pixelmap-schema": "parcel build pixelmap-schema.html --detailed-report --public-url ./",
    "clean:bv": "rm -rf ../../hyperlive/hyperlive/hyperlive-web-app/public/touchpoint",
    "build:bv": "yarn clean && yarn clean:bv && yarn build --dist-dir ../../hyperlive/hyperlive/hyperlive-web-app/public/touchpoint",
    "clean:abril": "rm -rf ../hyperlive-web-app/public/touchpoint",
    "build:abril": "yarn clean && yarn clean:abril && yarn build --dist-dir ../hyperlive-web-app/public/touchpoint",
    "build:bv:hoi": "yarn clean && yarn clean:bv && yarn build --dist-dir ../../hyperlive/hyperlive/hyperlive-displays/public",
    "clean:ic": "rm -rf ../hyperlive-web-app/public/touchpoint",
    "build:ic": "yarn clean && yarn clean:ic && yarn build --dist-dir ../hyperlive-web-app/public/touchpoint",
    "clean:al": "rm -rf ../hyperlive-web-app/public/touchpoint",
    "build:al": "yarn build --dist-dir ../hyperlive-web-app/public/touchpoint",
    "start": "yarn inject-env && yarn build:pixelmap-schema && parcel index.html hoi-livepreview.html tickers.js snkrs.js --port 1234",
    "start:pixelmap-schema": "yarn inject-env && parcel pixelmap-schema.html",
    "start:all": "parcel index.html --port 1234",
    "start:clean": "yarn inject-env && parcel index.html --port 1235",
    "dev": "open http://localhost:1234/channels.html && parcel *.html --port 1234",
    "start:hub": "open http://localhost:1234/?channel=test && yarn inject-env && parcel index.html --port 1234",
    "_deploy:staging": "aws s3 sync --acl=public-read dist/ s3://www.staging.enjoytheweather.com/rise/displays/ && aws cloudfront create-invalidation --distribution-id E21P0K74IA4JNZ --paths '/rise/displays/*'",
    "deploy:rise:staging": "export AWS_PROFILE=nde-rpe-experience-test && GENERATE_SOURCEMAP=false yarn build && aws s3 sync --acl=public-read dist/ s3://rise.staging.nikehyperlive.com/public/touchpoint/ && aws cloudfront create-invalidation --distribution-id E1UZ9VMZFSE37N --paths '/public/touchpoint/*'",
    "hoi:ticker": "parcel ./src/touchpoints/hoi/animations/ticker/static/index.html",
    "hoi:snkrs": "parcel ./src/touchpoints/hoi/animations/snkrs-calendar/index.html --no-cache",
    "hoi:speedshop": "parcel ./src/touchpoints/hoi/animations/speedshop/index.html",
    "live:coverstory": "parcel ./src/touchpoints/live/animations/coverstory/index.html --no-cache",
    "live:mannequin": "parcel ./src/touchpoints/live/animations/mannequin/index.html --no-cache",
    "live:catalog-apparel": "parcel ./src/touchpoints/live/animations/catalog-apparel/index.html --no-cache",
    "live:life365": "parcel ./src/touchpoints/live/animations/life365/index.html --no-cache",
    "live:program-messaging": "parcel ./src/touchpoints/live/animations/program-messaging/index.html --no-cache",
    "live:snkrs-cal": "parcel ./src/touchpoints/live/animations/snkrs-cal/index.html --no-cache",
    "sport:community-board": "parcel ./src/touchpoints/live/animations/community-board/index.html --no-cache",
    "superchrome": "open -a 'Google Chrome' --args --max-active-webgl-contexts=100",
    "jordan:core": "parcel src/touchpoints/jordan/animations/core/index.html --no-cache",
    "core:ticker": "parcel src/touchpoints/core/animations/ticker/index.html --no-cache",
    "jordan:cc": "parcel src/touchpoints/jordan/animations/center-court/index.html --no-cache",
    "x:ecosystem": "parcel src/touchpoints/x/animations/ecosystem-board/index.html --no-cache",
    "hoi:core": "parcel src/touchpoints/house-of-innovation/animations/core/index.html --no-cache",
    "core:membership": "parcel src/touchpoints/core/animations/membership/index.html --no-cache",
    "bump": "npx bump --tag --commit"
  },
  "dependencies": {
    "@babel/preset-env": "^7.16.11",
    "@babel/runtime": "^7.14.6",
    "@lottiefiles/react-lottie-player": "^3.4.7",
    "@parcel/babel-preset-env": "^2.3.1",
    "@react-three/drei": "^9.31.1",
    "@react-three/fiber": "^8.7.2",
    "@react-three/postprocessing": "^2.7.0",
    "@sentry/browser": "^6.11.0",
    "@sentry/integrations": "^6.11.0",
    "@sentry/react": "^6.11.0",
    "@sentry/tracing": "^6.11.0",
    "@tensorflow-models/body-pix": "^2.2.0",
    "@tensorflow/tfjs-backend-webgl": "^3.13.0",
    "@tensorflow/tfjs-converter": "^3.13.0",
    "@tensorflow/tfjs-core": "^3.13.0",
    "@tweakpane/plugin-essentials": "^0.1.4",
    "@types/three": "^0.144.0",
    "axios": "^0.26.0",
    "buffer": "^6.0.3",
    "chance": "^1.1.8",
    "classnames": "^2.3.1",
    "colorthief": "^2.3.2",
    "copy-to-clipboard": "^3.3.1",
    "dat.gui": "^0.7.7",
    "dayjs": "^1.11.7",
    "events": "^3.3.0",
    "fitty": "^2.3.6",
    "framer-motion": "^7.6.19",
    "glsl-noise": "^0.0.0",
    "gsap": "file:gsap-bonus.tgz",
    "html-to-image": "^1.11.11",
    "i18next": ">= 19.0.0",
    "intrinsic-scale": "^3.0.4",
    "localforage": "^1.10.0",
    "lodash": "^4.17.21",
    "lzutf8": "^0.6.0",
    "md5": "^2.3.0",
    "mime": "^2.5.2",
    "moment": "^2.29.3",
    "parcel-resolver-ignore": "^2.1.5",
    "pixi.js-legacy": "^5.3.10",
    "process": "^0.11.10",
    "prop-types": "^15.7.2",
    "r3f-perf": "^6.4.4",
    "react": "^18.2.0",
    "react-dom": "^18.2.0",
    "react-hls-player": "^3.0.7",
    "react-i18next": "^11.11.3",
    "react-inject-env": "^2.1.0",
    "react-intersection-observer": "^8.32.0",
    "react-ticker": "^1.3.2",
    "react-use-websocket": "^4.5.0",
    "rise-grid-framework": "git+https://etw-studio:ATBBJh95XuNBeFT62kvx5eF9eaF4F1FC3405@bitbucket.org/enjoytheweather/rise-grid-framework.git#v1.1.63",
    "src": "^1.1.2",
    "stats.js": "^0.17.0",
    "three": "0.144.0",
    "troika-three-text": "^0.46.4",
    "troika-three-utils": "^0.46.0",
    "tweakpane": "^3.0.5",
    "uuid": "^8.3.2",
    "vitals": "git+https://etw-studio:ATBBJh95XuNBeFT62kvx5eF9eaF4F1FC3405@bitbucket.org/enjoytheweather/vitals.git#v2.0.0",
    "web-vitals": "^3.0.1",
    "zustand": "^4.3.7"
  },
  "devDependencies": {
    "@babel/core": "^7.0.0-0",
    "@babel/plugin-syntax-bigint": "^7.8.3",
    "@babel/plugin-transform-runtime": "^7.14.5",
    "@babel/preset-env": "^7.16.11",
    "@parcel/compressor-gzip": "^2.7.0",
    "@parcel/optimizer-data-url": "2.7.0",
    "@parcel/resolver-glob": "^2.3.1",
    "@parcel/transformer-glsl": "^2.0.1",
    "@parcel/transformer-inline-string": "2.7.0",
    "@parcel/transformer-raw": "^2.2.1",
    "@parcel/transformer-sass": "^2.3.1",
    "@types/lodash": "^4.14.199",
    "@types/react-dom": "^18.2.8",
    "assert": "^2.0.0",
    "buffer": "^6.0.3",
    "glslify-bundle": "^5.1.1",
    "glslify-deps": "^1.3.2",
    "parcel": "^2.3.1",
    "parcel-reporter-static-files-copy": "^1.3.4",
    "path-browserify": "^1.0.1",
    "prettier": "^1.19.1",
    "process": "^0.11.10",
    "querystring-es3": "^0.2.1",
    "sass": "^1.35.2",
    "sharp": "^0.29.1",
    "typescript": "^4.3.5",
    "version-bump-prompt": "^5.0.1"
  },
  "parcelIgnore": [
    "000.config.chunk.js"
  ]
}
